.App {
    text-align: center;
    display: flex;
    justify-content: center;
}

.background-left {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 69vh;
    background-image: url("img/left-side.png");
    background-position: left;
    background-size: cover;
    opacity: 70%;
    z-index: -1;
}

.background-right {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("img/right-side.png");
    background-position: right;
    background-size: cover;
    width: 69vh;
    opacity: 70%;
    z-index: -1;
}

@media (max-width: 768px) {
    .background-left {
        opacity: 40%;
        background-image: url("img/left-side-mobile.png");
        width: 30vh;
    }

    .background-right {
        opacity: 40%;
        background-image: url("img/right-side-mobile.png");
        width: 30vh;
    }

}

.container {
    max-width: 758px;
    margin-left: 30px;
    margin-right: 30px;
}

.signature {
    margin-top: 16px;
    margin-bottom: 16px;
    font-family: 'corinthia', Arial, sans-serif;
    font-size: 80px;
    line-height: 80px;
}

.content-block {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button {
    font-family: 'corinthia', Arial, sans-serif;
    line-height: 1.55;
    font-weight: 300;
    border-width: 1px;
    border-radius: 10px;
    background-position: center center;
    border-color: #535c4b;
    border-style: solid;
    color: #7a7a7a;
    font-size: 45px;
    background-color: white;
    max-width: 550px;
    width: 350px;
    text-decoration: none;
    margin-top: 50px;
}

.title-h1 {
    font-family: 'corinthia', Arial, sans-serif;
    font-size: 80px;
    font-weight: 800;
    margin-top: 16px;
    margin-bottom: 16px;
}

.title-h2 {
    font-family: 'corinthia', Arial, sans-serif;
    font-size: 60px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.title-h3 {
    font-family: 'corinthia', Arial, sans-serif;
    font-size: 50px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.text {
    font-family: 'corinthia', Arial, sans-serif;
    font-size: 40px;
    margin-top: 8px;
    margin-bottom: 8px;
}
